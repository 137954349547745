/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  background: '#37474f';
} */

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.45;
  margin: 0;
  text-align: left; 
}

.scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

::-webkit-scrollbar {
  width: 7px;
  cursor: pointer;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.09);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c0c0c;
}


/* cor escura #263238  */
/* cor clara  #37474f  */


/* Definindo as cores */
:root {
  --cor-verde: #008000;
  /* Exemplo de verde */
  --cor-azul: #0000FF;
  /* Exemplo de azul */
  --cor-vermelho: #FF0000;
  /* Exemplo de vermelho */
  --cor-laranja: #FFA500;
  /* Exemplo de laranja */
  --cor-cinza-escuro: #333333;
  /* Exemplo de cinza escuro */
  --cor-cinza-claro: #CCCCCC;
  /* Exemplo de cinza claro */
}

:root {
  --cor-1: #323232;
  --cor-2: #3a3532;
  --cor-3: #464646;
  --cor-4: #95100b;
  --cor-5: #ED3237;
  --cor-6: #a60303;
  --cor-7: #f0f0f0;
  --cor-8: #0D84FF;
  --cor-9: #E4FF0D;
  --cor-10: #01FF1B;
  --cor-11: #3C3C3C;
  --cor-12: #ED3237;
  ;



  --cor-verde: #006400;
  /* Exemplo de verde */
  --cor-azul: #0000FF;
  /* Exemplo de azul */
  --cor-vermelho: #FF0000;
  /* Exemplo de vermelho */
  --cor-laranja: #FFA500;
  /* Exemplo de laranja */
  --cor-cinza-escuro: #333333;
  /* Exemplo de cinza escuro */
  --cor-cinza-claro: #CCCCCC;
  /* Exemplo de cinza claro */

  --Cinza-Claro: #CCCCCC;

  --Cinza-Médio: #808080;

  --Cinza-Escuro: #404040;

  --Cinza-Azulado: #6B6B6B;

  --Cinza-Esverdeado: #789B73;

  --Cinza-Quente: #A9A9A9;
}